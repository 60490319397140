// Icons to be included globally
import CLOSE_ICON from 'vue-ionicons/dist/md-close.vue'
export const CloseIcon = CLOSE_ICON

// Lazy loaded icons
export const ArrowDropupIcon = () => import('vue-ionicons/dist/md-arrow-dropup.vue')
export const ArrowDropdownIcon = () => import('vue-ionicons/dist/md-arrow-dropdown.vue')
export const SearchIcon = () => import('vue-ionicons/dist/md-search.vue')
export const DeleteIcon = () => import('vue-ionicons/dist/md-close.vue')
export const DeleteIconCircle = () => import('vue-ionicons/dist/md-close-circle.vue')
export const CheckmarkIcon = () => import('vue-ionicons/dist/md-checkmark.vue')
export const CheckmarkCircleIcon = () => import('vue-ionicons/dist/md-checkmark-circle.vue')
export const CheckmarkOutlinedIcon = () => import('./CheckmarkOutlinedIcon.vue')
export const WarningIcon = () => import('vue-ionicons/dist/md-warning.vue')
export const InfoIcon = () => import('vue-ionicons/dist/md-information-circle-outline.vue')
export const EditIcon = () => import('vue-ionicons/dist/md-create.vue')
export const CopyIcon = () => import('vue-ionicons/dist/md-copy.vue')
export const BackIcon = () => import('vue-ionicons/dist/md-arrow-back.vue')
export const ForwardIcon = () => import('vue-ionicons/dist/md-arrow-forward.vue')
export const CalendarIcon = () => import('vue-ionicons/dist/md-calendar.vue')
export const AddIcon = () => import('vue-ionicons/dist/md-add.vue')
export const AddCircleIcon = () => import('vue-ionicons/dist/md-add-circle-outline.vue')
export const RemoveIcon = () => import('vue-ionicons/dist/md-remove-circle-outline.vue')
export const ArrowDownIcon = () => import('vue-ionicons/dist/ios-arrow-down.vue')
export const ArrowUpIcon = () => import('vue-ionicons/dist/ios-arrow-up.vue')
export const ArrowForwardIcon = () => import('vue-ionicons/dist/ios-arrow-forward.vue')
export const ArrowForwardAltIcon = () => import('vue-ionicons/dist/md-arrow-forward.vue')
export const ListIcon = () => import('vue-ionicons/dist/md-paper.vue')
export const DownloadCloudIcon = () => import('vue-ionicons/dist/md-cloud-download.vue')
export const DownloadIcon = () => import('vue-ionicons/dist/md-download.vue')
export const GoogleIcon = () => import('vue-ionicons/dist/logo-google.vue')
export const OktaIcon = () => import('./OktaIcon.vue')
export const AzureIcon = () => import('vue-ionicons/dist/logo-windows.vue')
export const CameraIcon = () => import('vue-ionicons/dist/md-camera.vue')
export const LogoutIcon = () => import('vue-ionicons/dist/md-power.vue')
export const SwitchIcon = () => import('./SwitchIcon.vue')
export const AlertIcon = () => import('vue-ionicons/dist/md-alert.vue')
export const MedicalIcon = () => import('vue-ionicons/dist/md-medical.vue')
export const DragHandleIcon = () => import('vue-ionicons/dist/md-menu.vue')
export const ViewIcon = () => import('vue-ionicons/dist/md-eye.vue')
export const HiddenIcon = () => import('vue-ionicons/dist/md-eye-off.vue')
export const LinkIcon = () => import('vue-ionicons/dist/md-open.vue')
export const UnlinkedIcon = () => import('./UnlinkedIcon.vue')
export const PresentationIcon = () => import('./PresentationIcon.vue')
export const CampaignIOIcon = () => import('./CampaignIOIcon.vue')
export const ListViewIcon = () => import('./ListViewIcon.vue')
export const GalleryViewIcon = () => import('./GalleryViewIcon.vue')
export const FileDownloadIcon = () => import('./FileDownloadIcon.vue')
export const ActiveDotIcon = () => import('./ActiveDotIcon.vue')
export const ClipboardIcon = () => import('./ClipboardIcon.vue')
export const URLIcon = () => import('vue-ionicons/dist/md-link.vue')
export const StarIcon = () => import('vue-ionicons/dist/md-star.vue')
export const BuildIcon = () => import('vue-ionicons/dist/md-construct.vue')
export const CheckmarkCircleOutlineIcon = () => import('vue-ionicons/dist/md-checkmark-circle-outline.vue')
export const PinIcon = () => import('vue-ionicons/dist/md-pin.vue')
export const RadioButtonOffIcon = () => import('vue-ionicons/dist/md-radio-button-off.vue')
export const CompanyIcon = () => import('vue-ionicons/dist/md-person.vue')
export const GroupIcon = () => import('vue-ionicons/dist/md-people')
export const SettingsIcon = () => import('vue-ionicons/dist/md-settings.vue')
export const NotAllowedIcon = () => import('./NotAllowed.vue')
export const MoreIcon = () => import('vue-ionicons/dist/md-more.vue')
export const MapIcon = () => import('vue-ionicons/dist/md-map.vue')
export const NorthIcon = () => import('./NorthIcon.vue')
export const SouthIcon = () => import('./SouthIcon.vue')
export const FilePptIcon = () => import('./FilePptIcon.vue')
export const FilePdfIcon = () => import('./FilePdfIcon.vue')
export const FileExcelIcon = () => import('./FileExcelIcon.vue')
export const FileOutlookIcon = () => import('./FileOutlookIcon.vue')
export const FileImageIcon = () => import('./FileImageIcon.vue')
export const ConditionalOpenIcon = () => import('./ConditionalOpenIcon.vue')
export const ConditionalClosedIcon = () => import('./ConditionalClosedIcon.vue')
export const RefreshIcon = () => import('vue-ionicons/dist/md-refresh.vue')
export const DashboardIcon = () => import('./DashboardIcon.vue')
export const TrashIcon = () => import('./TrashIcon.vue')
export const DragDotIcon = () => import('./DragDotIcon.vue')
export const ClearIcon = () => import('./ClearIcon.vue')
export const LayoutIcon = () => import('./LayoutIcon.vue')
export const SpoofUserIcon = () => import('vue-ionicons/dist/md-contacts.vue')
export const LockIcon = () => import('vue-ionicons/dist/md-lock.vue')
export const UnlockIcon = () => import('vue-ionicons/dist/md-unlock.vue')
export const DoubleLeftArrowIcon = () => import('./DoubleLeftArrowIcon.vue')
export const DoubleRightArrowIcon = () => import('./DoubleRightArrowIcon.vue')
export const TableIcon = () => import('vue-ionicons/dist/md-apps.vue')
export const ProductIcon = () => import('./ProductIcon.vue')
export const StarNewIcon = () => import('./StarNewIcon.vue')
export const SortBothIcon = () => import('./SortBothIcon.vue')
export const SortRankIcon = () => import('./SortRankIcon.vue')
export const SortZAIcon = () => import('./SortZAIcon.vue')
export const SortAZIcon = () => import('./SortAZIcon.vue')
export const CircleCheckIcon = () => import('./CircleCheckIcon.vue')
export const CircleXIcon = () => import('./CircleXIcon.vue')
export const CircleAddIcon = () => import('./CircleAddIcon.vue')
export const ChevronRightIcon = () => import('./ChevronRightIcon.vue')
export const DeactivateIcon = () => import('./DeactivateIcon.vue')
export const VisibilityIcon = () => import('./VisibilityIcon.vue')
export const SupportIcon = () => import('./SupportIcon.vue')
export const NotesIcon = () => import('./NotesIcon.vue')
export const CrossIcon = () => import('./CrossIcon.vue')

export const SlideMenuToggleListIcon = () => import('./SlideMenuToggleListIcon.vue')
export const SlideMenuToggleThumbnailIcon = () => import('./SlideMenuToggleThumbnailIcon.vue')
export const ExternalLinkIcon = () => import('./ExternalLinkIcon.vue')
export const UnlinkUrlIcon = () => import('./UnlinkUrlIcon.vue')
export const DragDropIcon = () => import('./DragDropIcon.vue')
export const Star2Icon = () => import('./StarIcon.vue')
export const WorkspaceIcon = () => import('./WorkspaceIcon.vue')
export const ClientsIcon = () => import('./ClientsIcon.vue')
export const CampaignsIcon = () => import('./CampaignsIcon.vue')
export const CampaignsFilledIcon = () => import('./CampaignsFilledIcon.vue')
export const AgenciesIcon = () => import('./AgenciesIcon.vue')
export const ChatbotIcon = () => import('./ChatbotIcon.vue')
export const NotificationsIcon = () => import('./NotificationsIcon.vue')
export const ExpandIcon = () => import('./ExpandIcon.vue')
export const CondenseIcon = () => import('./CondenseIcon.vue')
export const AdvisrNotificationIcon = () => import('./AdvisrNotificationIcon.vue')
export const TasksIcon = () => import('./TasksIcon.vue')
export const TitanIcon = () => import('./TitanIcon.vue')
export const TaskIcon = () => import('./TaskIcon.vue')
export const TaskNotificationIcon = () => import('./TaskNotificationIcon.vue')
export const TaskExpandIcon = () => import('./TaskExpandIcon.vue')
export const TaskCondenseIcon = () => import('./TaskCondenseIcon.vue')
export const TaskAssignedIcon = () => import('./TaskAssignedIcon.vue')
export const TaskCreateIcon = () => import('./TaskCreateIcon.vue')
export const AdvisrWhiteIcon = () => import('./AdvisrWhiteIcon.vue')
export const WarningOutlineIcon = () => import('./WarningOutlineIcon.vue')
export const ContactsIcon = () => import('./ContactsIcon.vue')

// CampaignFileUpload
export const FileIcon = () => import('./FileIcon.vue')
export const FilterIcon = () => import('./FilterIcon.vue')
export const CommentBubbleIcon = () => import('./CommentBubbleIcon.vue')
export const LinkChainIcon = () => import('./LinkChainIcon.vue')
export const UploadIcon = () => import('./UploadIcon.vue')
export const TagIcon = () => import('./TagIcon.vue')
export const FileAudioIcon = () => import('./FileAudioIcon.vue')
export const ExternalLinkFileIcon = () => import('./ExternalLinkFileIcon.vue')
export const PowerpointIcon = () => import('./PowerpointIcon.vue')
export const TextIcon = () => import('./TextIcon.vue')
export const FileVideoIcon = () => import('./FileVideoIcon.vue')
export const FileWordIcon = () => import('./FileWordIcon.vue')
export const FileZipIcon = () => import('./FileZipIcon.vue')
export const FileTextIcon = () => import('./FileTextIcon.vue')
export const FileXmlIcon = () => import('./FileXmlIcon.vue')
export const ExternalDocumentIcon = () => import('./ExternalDocumentIcon.vue')
export const OrderCompleteIcon = () => import('./OrderCompleteIcon.vue')
export const OrderProcessingIcon = () => import('./OrderProcessingIcon.vue')
export const OrderWarningIcon = () => import('./OrderWarningIcon.vue')
export const DashboardActivityIcon = () => import('./DashboardActivityIcon.vue')
export const ViewAltIcon = () => import('./ViewAltIcon.vue')
export const EmailIcon = () => import('./EmailIcon.vue')
export const PhoneIcon = () => import('./PhoneIcon.vue')
export const WorkflowOptionalIcon = () => import('./WorkflowOptionalIcon.vue')
export const RankIcon = () => import('./RankIcon.vue')
export const BudgetIcon = () => import('./BudgetIcon.vue')
export const ClientIcon = () => import('./ClientIcon.vue')
export const EmailOpenedIcon = () => import('./EmailOpenedIcon.vue')
export const EmailClickedIcon = () => import('./EmailClickedIcon.vue')
export const EmailSentIcon = () => import('./EmailSentIcon.vue')
export const InviteDeclinedIcon = () => import('./InviteDeclinedIcon.vue')

// Nav icons
export const NavIcons = {
  MenuIcon: () => import('vue-ionicons/dist/md-menu.vue'),
  MenuCloseIcon: () => import('vue-ionicons/dist/md-close.vue'),
  DashboardIcon: () => import('vue-ionicons/dist/md-home.vue'),
  ClientsIcon: () => import('vue-ionicons/dist/md-contacts.vue'),
  ProductsIcon: () => import('vue-ionicons/dist/md-pricetags.vue'),
  NotificationsIcon: () => import('vue-ionicons/dist/md-notifications.vue'),
  ReportingIcon: () => import('vue-ionicons/dist/md-stats.vue'),
  SettingsIcon: () => import('vue-ionicons/dist/md-settings.vue'),
  WorkspaceIcon: () => import('./WorkspaceIcon.vue'),
  SupportIcon: () => import('vue-ionicons/dist/md-help-circle-outline.vue'),
  LogoutIcon: () => import('vue-ionicons/dist/md-power.vue'),
  MediaAssetIcon: () => import('./MediaAssetIcon.vue'),
  BookmarkIcon: () => import('./BookmarkIcon.vue')
}

// Demographic icons
export const DemographicIcons = {
  AgeIcon: () => import('vue-ionicons/dist/md-calendar.vue'),
  IncomeIcon: () => import('vue-ionicons/dist/md-cash.vue'),
  GenderIcon: () => import('vue-ionicons/dist/md-person.vue'),
  HouseholdIcon: () => import('vue-ionicons/dist/md-home.vue'),
  MaleIcon: () => import('vue-ionicons/dist/md-male.vue'),
  FemaleIcon: () => import('vue-ionicons/dist/md-female.vue'),
  DollarIcon: () => import('./DollarIcon.vue'),
  PeopleIcon: () => import('./PeopleIcon.vue')
}

// Multiselect icons
export const MultiselectIcons = {
  AddIcon: () => import('vue-ionicons/dist/md-play.vue'),
  RemoveAllIcon: () => import('vue-ionicons/dist/md-rewind.vue'),
  AddAllIcon: () => import('vue-ionicons/dist/md-fastforward.vue')
}

export const DashboardIcons = {
  DashboardActivityIcon: () => import('./DashboardActivityIcon.vue'),
  DashboardNavigateCircleIcon: () => import('./DashboardNavigateCircleIcon.vue'),
  DashboardPersonIcon: () => import('./DashboardPersonIcon.vue'),
  DashboardPresentationIcon: () => import('./DashboardPresentationIcon.vue'),
  DashboardStatsIcon: () => import('./DashboardStatsIcon.vue'),
  DashboardGraphsIcon: () => import('./DashboardGraphsIcon.vue'),
  DashboardTrophyIcon: () => import('./DashboardTrophyIcon.vue'),
  DashboardInsightIcon: () => import('./InsightIcon.vue')
}

export const ExternalSystemIcons = {
  SalesforceIcon: () => import('./externalsystems/Salesforce.vue'),
  SellIcon: () => import('./externalsystems/Sell.vue'),
  AdpointIcon: () => import('./externalsystems/Adpoint.vue'),
  OperativeIcon: () => import('./externalsystems/Operative.vue'),
  TapOrdersIcon: () => import('./externalsystems/TapOrders.vue'),
  TitanIcon: () => import('./externalsystems/Titan.vue'),
  AOSIcon: () => import('./externalsystems/AOS.vue'),
  MicrosoftD365Icon: () => import('./externalsystems/MicrosoftD365.vue'),
  HubspotIcon: () => import('./externalsystems/Hubspot.vue'),
  DefaultIcon: () => import('./externalsystems/Default.vue')
}
