import Vue from 'vue'

function updateEmailTemplateMapping ({ id, template, version, isEnabled } = { }) {
  const path = `/admin/email-template-mappings/${id}`
  const data = { template, version, isEnabled }
  return Vue.api.patch(path, { data })
}

function createEmailTempalteMapping ({ userNotificationTypeId, template, version, isEnabled } = { }) {
  const path = '/admin/email-template-mappings'
  const data = { userNotificationTypeId, template, version, isEnabled }
  return Vue.api.post(path, { data })
}

const actions = {
  adminFetchEmailTemplateMappings (context) {
    const path = '/admin/email-template-mappings'
    return Vue.api.get(path)
  },

  adminSaveEmailTemplateMapping (context, { id, userNotificationTypeId, template, version, isEnabled } = { }) {
    if (id) {
      return updateEmailTemplateMapping({ id, template, version, isEnabled })
    } else {
      return createEmailTempalteMapping({ userNotificationTypeId, template, version, isEnabled })
    }
  }
}

export default actions
