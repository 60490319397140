import Vue from 'vue'

function updateStoreList (response) {
  Vue.$store.state.Contact.list = response
  return response
}

function updateStoreContact (id) {
  return function (response) {
    const contacts = [...Vue.$store.state.Contact.list.data]
    const idx = contacts.findIndex((contact) => contact.id === id)
    contacts[idx] = response
    Vue.$store.state.Contact.list.data = contacts
    return response
  }
}

function create (data = {}) {
  return Vue.api.post('/contacts', { data })
}

function list (params = {}) {
  return Vue.api.get('/contacts', { params })
    .then(updateStoreList)
}

function load (id) {
  return Vue.api
    .get(`/contacts/${id}`)
    .then(updateStoreContact(id))
}

function update (id, data = {}) {
  return Vue.api
    .patch(`/contacts/${id}`, { data })
    .then(updateStoreContact(id))
}

function _delete (id) {
  return Vue.api.delete(`/contacts/${id}`)
}

export default {
  create,
  list,
  load,
  update,
  delete: _delete
}
