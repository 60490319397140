import Vue from 'vue'
import { api } from '@/config'
import { pickBy } from 'lodash-es'
const { apiRoot } = api

const actions = {

  getCampaignListForClient (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list-for-client', {
          params
        })
        .then(response => {
          context.commit('SET_CAMPAIGN_LIST', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  getCampaignListForWorkspace (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list-for-workspace', {
          params
        })
        .then(response => {
          context.commit('SET_CAMPAIGN_LIST', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  getCampaignList (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list', {
          params
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  getCampaignViews (context, presentationId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/views', {
          params: { presentationId }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  saveNewCampaign (context, campaign) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/new', { campaign })
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignCustomFields (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/' + data.id + '/custom-fields', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaign (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load/' + id)
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          context.commit('SET_CLIENT', response.body.client)
          context.commit('SET_CAMPAIGN_SUBMISSION_COMMENT', response.body.campaign.submissionComment)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  deleteCampaign (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/delete', data)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaign (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update', {
          id: context.state.campaign.id,
          data
        })
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignToPreviousActiveStep (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-to-previous-active-step', {
          id: context.state.campaign.id
        })
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignCancelEdit (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/cancel-edit', {
          id: context.state.campaign.id
        })
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  // campaignCreateNoProducts (context, data) {
  //   return new Promise((resolve, reject) => {
  //     Vue.http
  //       .post(apiRoot + '/campaign/create-no-products', Object.assign({ id: context.state.campaign.id }, data))
  //       .then(response => {
  //         context.commit('SET_CAMPAIGN', response.body.campaign)
  //         resolve()
  //       })
  //       .catch(error => reject(error))
  //   })
  // },

  markCampaignAsComplete (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/mark-complete', Object.assign({ id: context.state.campaign.id }, data))
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignBudget (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-budget', Object.assign({ id: context.state.campaign.id }, data))
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignAgency (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-agency', Object.assign({ id: context.state.campaign.id }, data))
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignObjectives (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-objectives', Object.assign({ id: context.state.campaign.id }, data))
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)

          if (data.resetProducts) {
            context.commit('SET_CAMPAIGN_PRODUCTS_PRIMARY', null)
            context.commit('SET_CAMPAIGN_PRODUCTS_METADATA', null)
            context.commit('SET_CAMPAIGN_PRODUCTS_RANKED_AND_RECOMMENDED', null)
          }
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignOwner (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-owner', Object.assign({ id: context.state.campaign.id }, data))
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignTeamMembers (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-team-members', Object.assign({ id: context.state.campaign.id }, data))
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  setCampaignStatusToEditing (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/editing-status', {
          id: context.state.campaign.id,
          data
        })
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  convertExpressCampaign (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/convert-express', {
          id: context.state.campaign.id,
          data: data.data,
          addresses: data.addresses
        })
        .then(response => {
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignAddresses (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-addresses', {
          id: context.state.campaign.id,
          data
        })
        .then(response => {
          context.commit('SET_CLIENT', response.body.client)
          context.commit('SET_CAMPAIGN', response.body.campaign)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadSessionsForCampaign (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http.get(apiRoot + '/campaign/load-sessions', {
        params
      }).then(response => {
        context.commit('SET_SESSIONS', response.body)
        resolve()
      }).catch(error => reject(error))
    })
  },

  generateCampaignExport (context, params) {
    return new Promise((resolve, reject) => {
      const serverRequest = async (attempts = 0) => {
        if (attempts > 120) return reject(new Error('Error requesting campaign export'))
        try {
          const response = await Vue.http
            .put(`${apiRoot}/campaigns/${context.state.campaign.id}/exports`, params)

          if (response.status === 200) {
            const { url } = response.body
            return resolve(url)
          } else if (response.status === 202) setTimeout(() => serverRequest(attempts + 1), 1000)
          else reject(new Error('Error requesting campaign export: ' + response.body))
        } catch (error) {
          if (error instanceof Error) reject(error)
          else reject(new Error(`Error requesting campaign export, status: ${error.status}, url ${error.url}`))
        }
      }

      serverRequest()
    })
  },

  findCampaignByNameAndClient (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http.get(apiRoot + '/campaign/find-by-name-and-client', {
        params
      }).then(response => {
        resolve(response.body)
      }).catch(error => reject(error))
    })
  },

  checkCampaignNameForClients (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http.get(apiRoot + '/campaign/check-name-for-clients', {
        params
      }).then(response => {
        resolve(response.body)
      }).catch(error => reject(error))
    })
  },

  copyCampaign (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http.post(apiRoot + '/campaign/copy', data)
        .then(response => { resolve(response.body) })
        .catch(error => reject(error.body))
    })
  },

  copyCampaignToClient (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/copy-to-client', data)
        .then(response => { resolve(response.body) })
        .catch(error => reject(error.body))
    })
  },

  uploadCampaignAsset (context, formData) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/upload-asset', formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  editCampaignLocation (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/edit-location', data)
        .then(response => {
          context.commit('SET_CAMPAIGN_LOCATION', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignSupportSubmissions (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-support-submissions', {
          params: { id: context.state.campaign.id }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignSupportSubmission (context, submissionId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-support-submission', {
          params: {
            id: context.state.campaign.id,
            submissionId
          }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  submitCampaignSupportSubmission (context, data) {
    return new Promise((resolve, reject) => {
      const serverRequest = async () => {
        try {
          const response = await Vue.http
            .post(apiRoot + '/campaign/submit-support-submission', data)

          if (response.status === 200) {
            resolve(response.body)
          } else if (response.status === 202) {
            setTimeout(serverRequest, 1000)
          } else {
            reject(new Error('Error with support submission: ' + response.body))
          }
        } catch (error) {
          reject(error.body)
        }
      }

      serverRequest()
    })
  },

  saveCampaignSupportSubmission (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/save-support-submission', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadCampaignFulfillmentSubmissions (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-fulfillment-submissions', {
          params: { id: context.state.campaign.id }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignFulfillmentSubmission (context, submissionId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-fulfillment-submission', {
          params: {
            id: context.state.campaign.id,
            submissionId
          }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  saveCampaignFulfillmentSubmission (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/save-fulfillment-submission', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  loadCampaignWorkflowSubmissions (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-workflow-submissions', {
          params: { id: context.state.campaign.id }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignWorkflowSubmission (context, submissionId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-workflow-submission', {
          params: {
            id: context.state.campaign.id,
            submissionId
          }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  submitCampaignWorkflowSubmission (context, data) {
    return new Promise((resolve, reject) => {
      const serverRequest = async () => {
        try {
          const response = await Vue.http
            .post(apiRoot + '/campaign/submit-workflow-submission', data)

          if (response.status === 200) {
            resolve(response.body)
          } else if (response.status === 202) {
            setTimeout(serverRequest, 1000)
          } else {
            reject(new Error('Error with workflow submission: ' + response.body))
          }
        } catch (error) {
          reject(error.body)
        }
      }

      serverRequest()
    })
  },

  reopenCampaignWorkflowSubmission (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/reopen-workflow-submission', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  reopenCampaignSupportSubmission (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/reopen-support-submission', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },
  saveCampaignWorkflowSubmission (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/save-workflow-submission', data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  submitCampaignOrder (context, data) {
    return new Promise((resolve, reject) => {
      const MAX_STATUS_CHECKS = 100
      let statusCheckCounter = 0

      const checkSubmissionStatus = async () => {
        if (MAX_STATUS_CHECKS === statusCheckCounter) {
          // reject(new Error('Maximum attemps to read the submission status reached'))
          resolve()
        }

        const response = await Vue.http
          .get(apiRoot + `/campaign/${data.id}/submission-status`)
          .catch(() => {
            reject(new Error('Error occurred while submitting the campaign'))
          })

        if (response.status === 200) {
          resolve(response.body)
        } else if (response.status === 202) {
          statusCheckCounter++
          setTimeout(checkSubmissionStatus, 1000)
        } else {
          reject(new Error('Error with campaign submission: ' + response.body))
        }
      }

      Vue.http
        .post(apiRoot + '/campaign/submit-order', data)
        .then(() => {
          return checkSubmissionStatus()
        })
        .catch((error) => {
          if (error?.body?.message) {
            return reject(new Error(`Error occurred while submitting the campaign: ${error.body.message}`))
          }
          reject(new Error('Error occurred while submitting the campaign'))
        })
    })
  },

  loadCampaignFulfillmentModules (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-fulfillment-modules', {
          params: { id: context.state.campaign.id }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignProposalTemplates (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-templates', {
          params: { id: context.state.campaign.id }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignProposalSlides (context) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-slides', {
          params: { id: context.state.campaign.id }
        })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignPotentialOwners (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-potential-owners', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  loadCampaignPotentialTeamMembers (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-potential-team-members', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  loadGroupMembers (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-group-members', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  loadCampaignUserRoles (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-user-roles', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  loadCampaignExternalLinks (context, params) {
    const id = params.id
    const persistInContext = typeof (params.persistInContext) === 'undefined' ? true : params.persistInContext
    delete params.id
    delete params.persistInContext

    if (persistInContext) {
      context.commit('SET_CAMPAIGN_EXTERNAL_LINKS', [])
    }

    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-external-links/' + id, { params })
        .then(response => {
          if (persistInContext) {
            context.commit('SET_CAMPAIGN_EXTERNAL_LINKS', response.body)
          }
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignExternalLink (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/load-external-link/' + params.id, { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignExternalLink (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/update-external-link', params)
        .then(response => {
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  syncCampaignExternalCrmLines (context, params) {
    const { campaignId, externalCRMId } = params
    const path = `/campaigns/${campaignId}/external-crms/${externalCRMId}/sync-opportunity-lines-requests`
    return Vue.api.post(path)
  },

  listExternalCampaigns (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list-external-campaigns', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  listInternalCampaigns (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list-internal-campaigns', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  setPrimaryCampaign (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/set-primary-campaign', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  listExternalQuotes (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list-external-quotes', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  pushCampaignDataToCrm (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/push-data-to-crm', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  listCampaignDataForCrmPush (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign/list-campaign-data-for-crm-push', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  saveCampaignCrmPushData (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(apiRoot + '/campaign/save-crm-push-data', params)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  /**
   * Audience actions
  */
  loadCampaignAudienceCategories (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/audience/load-categories`)
        .then(response => {
          context.commit('SET_CAMPAIGN_AUDIENCE_CATEGORIES', {
            campaignId,
            values: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignAudienceRecommended (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/audience/load-recommended-segments`)
        .then(response => {
          context.commit('SET_CAMPAIGN_AUDIENCE_RECOMMENDED', {
            campaignId,
            values: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignAudiencePrimary (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/audience/load-primary-segments`)
        .then(response => {
          context.commit('SET_CAMPAIGN_AUDIENCE_PRIMARY', {
            campaignId,
            values: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignAudienceAvailable (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/audience/load-available`)
        .then(response => {
          context.commit('SET_CAMPAIGN_AUDIENCE_AVAILABLE', {
            campaignId,
            values: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  saveCampaignAudiencePrimary (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${params.campaignId}/audience/save-primary`, params.data)
        .then(response => {
          context.commit('SET_CAMPAIGN_AUDIENCE_PRIMARY', {
            campaignId: params.campaignId,
            values: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  /**
   * Products actions
  */
  loadCampaignProductsPrimary (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/products/load-primary-selection`)
        .then(response => {
          context.commit('SET_CAMPAIGN_PRODUCTS_PRIMARY', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignProductsMetadata (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/products/load-metadata`)
        .then(response => {
          context.commit('SET_CAMPAIGN_PRODUCTS_METADATA', {
            campaignId,
            products: response.body
          })
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignProductsRankedAndRecommended (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/products/load-ranked-and-recommended`)
        .then(response => {
          context.commit('SET_CAMPAIGN_PRODUCTS_RANKED_AND_RECOMMENDED', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  saveCampaignProductsPrimary (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${data.campaignId}/products/save-primary`, data)
        .then(response => {
          context.commit('SET_CAMPAIGN_PRODUCTS_PRIMARY', response.body)
          if (data.updateMetadata) {
            context.commit('SET_CAMPAIGN_PRODUCTS_METADATA', null)
          }
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  saveCampaignProductsPrimaryFlags (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${data.campaignId}/products/update-primary-flags`, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  /**
   * Geo actions
  */

  loadCampaignGeoRecommended (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/geo/load-recommended`)
        .then(response => {
          context.commit('SET_CAMPAIGN_GEO_RECOMMENDED', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  loadCampaignGeoPrimary (context, campaignId) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${campaignId}/geo/load-primary`)
        .then(response => {
          context.commit('SET_CAMPAIGN_GEO_PRIMARY', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  saveCampaignGeoPrimary (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${data.campaignId}/geo/save-primary`, data)
        .then(response => {
          context.commit('SET_CAMPAIGN_GEO_PRIMARY', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  saveCampaignGeoRadii (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${data.campaignId}/geo/save-radii`, data)
        .then(response => {
          context.commit('SET_CAMPAIGN_GEO_PRIMARY', response.body)
          resolve()
        })
        .catch(error => reject(error))
    })
  },

  /* campaign-fields */

  loadCampaignField (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign-fields/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  listAllCampaignFields (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign-fields/list', { params })
        .then(response => {
          context.commit('SET_CAMPAIGN_FIELDS', response.body)
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  /* campaign-types */

  loadCampaignType (context, id) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign-type/' + id)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  listAllCampaignTypes (context, params) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign-type/list', { params })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  /* campaign-files */

  uploadCampaignFile (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign-file/file-upload`, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  listCampaignFiles (context, { campaignId }) {
    return Vue.api.get(`/campaigns/${campaignId}/campaign-files`)
  },

  listCampaignFileComments (context, { campaignId, commentableId }) {
    return Vue.api.get(`/campaigns/${campaignId}/campaign-files/${commentableId}/comments`)
  },

  getPresignedCampaignFile (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .get(apiRoot + '/campaign-file/presigned-link', { params: data })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  updateCampaignFile (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign-file/file-upload-update`, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  linkCampaignFile (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign-file/file-link`, data)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  softDeleteCampaignFiles (context, data) {
    const ids = data
    if (ids.length === 0) { return }

    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign-file/soft-delete`, { ids })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  restoreCampaignFiles (context, data) {
    const ids = data
    if (ids.length === 0) { return }

    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign-file/restore`, { ids })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  addTagsToFiles (context, { userId, files, tags }) {
    return new Promise((resolve, reject) => {
      Vue.http.post(apiRoot + '/campaign-file/tag-files', { userId, files, tags })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error.body))
    })
  },

  listCampaignComments (context, campaignId) {
    return Vue.http.get(apiRoot + '/campaigns/' + campaignId + '/comments')
      .then(response => response.body)
      .catch(error => error.body)
  },
  createCampaignComment (context, data) {
    return Vue.http.post(apiRoot + '/campaigns/' + data.commentableId + '/comments', data)
      .then(response => response.body)
      .catch(error => error.body)
  },
  deleteCampaignComment (context, data) {
    return Vue.http.delete(apiRoot + '/campaigns/' + data.commentableId + '/comments/' + data.id)
      .then(response => response.body)
      .catch(error => error.body)
  },

  /* campaign schedules */
  listCampaignSchedules (context, params) {
    const queryParams = Object.assign({}, params)
    delete queryParams.campaignId

    return new Promise((resolve, reject) => {
      Vue.http
        .get(`${apiRoot}/campaign/${params.campaignId}/schedules/list`, { params: queryParams })
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  prioritizeCampaignSchedules (context, { campaignId, scheduleId, newPriority }) {
    const path = `/campaigns/${campaignId}/schedules/${scheduleId}/priority`
    const params = pickBy({ campaignId, scheduleId }, (v) => v !== undefined)
    const data = pickBy({ newPriority }, (v) => v !== undefined)

    return Vue.api.put(path, { params, data })
  },

  uploadCampaignSchedule (context, data) {
    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${data.campaignId}/schedules/upload`, data.formData)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => {
          if (error?.body?.message) reject(new Error(error.body.message))
          reject(error)
        })
    })
  },

  updateCampaignSchedule (context, params) {
    const postParams = Object.assign({}, params)
    delete postParams.campaignId
    delete postParams.scheduleId

    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${params.campaignId}/schedules/${params.scheduleId}/update`, postParams)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  updateCampaignScheduleOutlet (context, params) {
    const postParams = Object.assign({}, params)
    delete postParams.campaignId
    delete postParams.scheduleId
    delete postParams.outletId

    return new Promise((resolve, reject) => {
      Vue.http
        .post(`${apiRoot}/campaign/${params.campaignId}/schedules/${params.scheduleId}/outlets/${params.outletId}/update`, postParams)
        .then(response => {
          resolve(response.body)
        })
        .catch(error => reject(error))
    })
  },

  createEmbeddedSigningLink (context, data) {
    const campaignId = data.get('campaignId')
    data.delete('campaignId')

    const path = `/campaigns/${campaignId}/e-signature-document-url`

    return Vue.api.post(path, { data })
  },

  getDocument (context, { campaignId, documentId }) {
    const path = `/campaigns/${campaignId}/e-signature-documents/${documentId}`

    return Vue.api.get(path)
  },

  cancelSignatureInvitations (context, { campaignId, documentId, reason }) {
    const path = `/campaigns/${campaignId}/e-signature-documents/signature-invitations`
    const data = pickBy({ reason, documentId }, (v) => v !== undefined)

    return Vue.api.put(path, { data })
  },

  resendSignatureInvitation (context, { campaignId, fieldRequestId }) {
    const path = `/campaigns/${campaignId}/e-signature-documents/signature-invitations/re-send`
    const data = pickBy({ fieldRequestId }, (v) => v !== undefined)

    return Vue.api.put(path, { data })
  },

  replaceFieldInviteRecipient (context, { campaignId, signatureInvitationId, roleId, email, documentId, daysUntilExpiration, reminder }) {
    const path = `/campaigns/${campaignId}/e-signature-documents/signature-invitations/replace-recipient`
    const data = pickBy({ signatureInvitationId, roleId, email, documentId, daysUntilExpiration, reminder }, (v) => v !== undefined)

    return Vue.api.put(path, { data })
  },

  uploadCommentToCampaignFile (context, data) {
    const path = `/campaigns/${data.campaignId}/campaign-files/${data.commentableId}/comments`

    return Vue.api.post(path, { data })
  },

  deleteCommentFromCampaignFile (context, data) {
    const path = `/campaigns/${data.campaignId}/campaign-files/${data.commentableId}/comments/${data.id}`

    return Vue.api.delete(path, { data })
  }
}

export default actions
