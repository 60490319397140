import admin from './admin'
import campaigns from './campaigns'
import contacts from './contacts'
import contactMethods from './contactMethods'

export default {
  admin,
  campaigns,
  contacts,
  contactMethods
}
