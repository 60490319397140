<template>
  <div style="display: none;" />
</template>
<script>

export default {
  name: 'DeeplinkHandler',

  mounted () {
    this.$bus.$on('deeplink:comments', this.routeToComment)
  },

  beforeDestroy () {
    this.$bus.$on('deeplink:comments')
  },

  methods: {
    routeToComment (params = {}) {
      switch (params.commentableType) {
        case 'CampaignFile':
          this.$router.push({
            name: 'CampaignFiles',
            params: { id: params.campaignId },
            query: {
              campaignFileId: params.commentableId,
              commentId: params.commentId
            }
          })
          break

        case 'Campaign':
          this.$router.push({
            name: 'CampaignSummary',
            params: { id: params.commentableId },
            query: {
              campaignCommentId: params.commentId
            }
          })
          break
      }
    }
  }
}
</script>
