import Vue from 'vue'

function create (contactId, data = {}) {
  return Vue.api.post(`/contacts/${contactId}/contact-methods`, { data })
}

function update (contactId, id, data = {}) {
  return Vue.api.patch(`/contacts/${contactId}/contact-methods/${id}`, { data })
}

function order (contactId, contactMethodId, data = {}) {
  return Vue.api.patch(`/contacts/${contactId}/contact-methods/${contactMethodId}/order`, { data })
}

function _delete (contactId, id) {
  return Vue.api.delete(`/contacts/${contactId}/contact-methods/${id}`)
}

export default {
  create,
  update,
  order,
  delete: _delete
}
