export default {

  methods: {
    openTaskPaneToTask (userTaskId) {
      this.$router.push({ path: this.$route.path, query: { taskId: userTaskId } })
        .catch(() => {})
    },

    goToCampaignComment ({ id, commentId }) {
      if (
        this.$route.name === 'CampaignSummary' &&
          parseInt(this.$route.params.id) === id &&
          parseInt(this.$route.query.campaignCommentId) === commentId
      ) {
        window.location.reload()
      } else {
        this.$router.push({
          name: 'CampaignSummary',
          params: { id },
          query: { campaignCommentId: commentId }
        })
      }
    },

    goToCampaignFileComment ({ id, campaignId, commentId }) {
      const fileListPath = /^\/campaign\/[0-9]*\/files$/
      if (
        this.$route.name === 'CampaignFiles' &&
          fileListPath.test(this.$route.fullPath) &&
          parseInt(this.$route.params.id) === campaignId &&
          parseInt(this.$route.query.campaignFileId) === id &&
          parseInt(this.$route.query.campaignFileCommentId) === commentId
      ) {
        window.location.reload()
      } else {
        this.$router.push({
          path: `/campaign/${campaignId}/files`,
          query: { campaignFileId: id, commentId }
        })
      }
    },

    goToMediaAssetComment ({ id, companyId, commentId }) {
      if (this.$route.name === 'AssetLibrary' &&
          parseInt(this.$route.query.mediaAssetId) === id &&
          parseInt(this.$route.query.mediaAssetCommentId) === commentId &&
          parseInt(this.$route.query.companyId) === companyId
      ) {
        window.location.reload()
      } else {
        this.$router.replace({
          name: 'AssetLibrary',
          query: {
            mediaAssetId: id,
            mediaAssetCommentId: commentId,
            companyId
          }
        })
      }
    },
    goToCampaignESignatureDocument ({ id, submissionId, documentType, showWhere }) {
      const routeName = showWhere === 'workflow' ? 'CampaignWorkflowForm' : 'CampaignSupportFormV2'
      if (
        this.$route.name === routeName &&
          parseInt(this.$route.params.id) === id &&
          parseInt(this.$route.query.submissionId) === submissionId
      ) {
        window.location.reload()
      } else {
        this.$router.push({
          name: routeName,
          params: { id, submissionId },
          query: {
            documentType
          }
        })
      }
    }
  }
}
