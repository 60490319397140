import PermissionActions from './Permission'
import CompanyActions from './Company'
import GroupActions from './Group'
import UserActions from './User'
import UserRoleActions from './UserRole'
import ProductActions from './Product'
import DataSourceActions from './DataSource'
import ReportingCategoryActions from './ReportingCategory'
import IndustryActions from './Industry'
import CampaignGoalActions from './CampaignGoal'
import FormActions from './Form'
import AddonsActions from './Addons'
import VendorActions from './Vendor'
import SubmissionActions from './Submission'
import TemplatesActions from './Templates'
import FulfillmentModuleActions from './FulfillmentModule'
import AudienceActions from './Audience'
import SlidesActions from './Slides'
import SSOProviderActions from './SSOProvider'
import ExternalApiAccessActions from './ExternalApiAccess'
import AdminActions from './Admin'
import ApiRequestLogsActions from './ApiRequestLogs'
import PresentationActions from './Presentation'
import ProductFees from './Fees'
import CustomFieldsActions from './CustomFields'
import SupportUserActions from './SupportUser'
import ExternalCRMActions from './ExternalCRM'
import ReportingActions from './Reporting'
import InternalCRMActions from './InternalCRM'
import CampaignStatusesActions from './CampaignStatuses'
import Tags from './Tags'
import CampaignField from './CampaignField'
import CampaignType from './CampaignType'
import Dashboard from './Dashboard'
import NotificationStrategyConfiguration from './NotificationStrategyConfiguration'
import EmailTemplateMapping from './EmailTemplateMapping'
import ExternalIntegrationInstallation from './ExternalIntegrationInstallation'
import Bookmarks from './Bookmarks'

export default Object.assign(
  {},
  PermissionActions,
  CompanyActions,
  GroupActions,
  UserActions,
  UserRoleActions,
  ProductActions,
  DataSourceActions,
  ReportingCategoryActions,
  IndustryActions,
  CampaignGoalActions,
  FormActions,
  AddonsActions,
  VendorActions,
  SubmissionActions,
  AudienceActions,
  TemplatesActions,
  FulfillmentModuleActions,
  SlidesActions,
  SSOProviderActions,
  ExternalApiAccessActions,
  AdminActions,
  ApiRequestLogsActions,
  PresentationActions,
  ProductFees,
  CustomFieldsActions,
  SupportUserActions,
  ExternalCRMActions,
  ReportingActions,
  InternalCRMActions,
  CampaignStatusesActions,
  Tags,
  CampaignField,
  CampaignType,
  Dashboard,
  NotificationStrategyConfiguration,
  EmailTemplateMapping,
  ExternalIntegrationInstallation,
  Bookmarks
)
