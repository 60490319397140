<template>
  <a-dialog
    overlay="dark"
    type="info"
    :button-text="task===null ? 'Create' : 'Update'"
    vertical-height="grow"
    horizontal-width="grow"
    cancel-button-text="Cancel"
    cancel-button
    :disabled="taskDetails==='' || selectedAssignees.length===0"
    :primary-button="true"
    :button-action="task===null ? createTask : updateTask"
    class="dialog"
    @close-dialog="cancel"
  >
    <template #header>
      <div v-if="task===null">
        Create a Task
      </div>
      <div v-else>
        Edit Task
      </div>
    </template>
    <template #content>
      <page-spinner
        v-if="isDataSubmitted"
        class="spinner-submit"
      />

      <div class="task-details">
        Task Details
      </div>

      <a-textarea
        ref="aTextArea"
        v-model="taskDetails"
        placeholder="Provide task details... eg. change campaign budget"
        rows="6"
      />
      <div class="d-flex flex-row justify-content-between">
        <div>
          <div class="assign">
            Assign
          </div>
          <task-assignee-multi-select
            :options="groupUsers"
            :initial-assignment-state="true"
            @assign="handleAssign"
          />
          <div />
        </div>
        <div style="">
          <div class="assign">
            Deadline
          </div>
          <date-picker
            v-model="deadline"
          />
        </div>
      </div>
    </template>
  </a-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { pick } from 'lodash-es'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import TaskAssigneeMultiSelect from './TaskAssigneeMultiSelect.vue'
import CommentTaskProcess from './CommentTaskProcess'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  components: {
    TaskAssigneeMultiSelect
  },
  mixins: [
    CommentTaskProcess
  ],
  props: {
    task: {
      type: Object,
      required: false,
      default: null
    }
  },

  data () {
    return {
      isDataSubmitted: false,
      taskDetails: '',
      deadline: null,
      selectedAssignees: [],
      groupUsers: []
    }
  },
  computed: {
    user: {
      get () {
        return this.$store.state.User.user
      }
    },
    company: {
      get () {
        return this.$store.state.Group.company
      }
    }
  },

  mounted () {
    if (this.task) {
      const htmlString = this.task.details
      const tempElement = document.createElement('div')
      tempElement.innerHTML = htmlString
      this.taskDetails = tempElement.textContent || tempElement.innerText || ''
      this.deadline = this.formatDate(this.task.dueDate)
    } else {
      this.taskDetails = ''
      this.dueDate = null
    }
    this.setInputFocus()
  },
  async created () {
    await this.populateUserList()
  },

  methods: {
    cancel () {
      this.$emit('cancel')
    },

    formatDate (datetime) {
      return datetime && dayjs.tz(datetime, 'America/Toronto').format('YYYY-MM-DD')
    },

    setInputFocus () {
      if (this.$refs.aTextArea) {
        this.$refs.aTextArea.$refs.inputElement.focus()
      } else setTimeout(this.setInputFocus, 100)
    },

    async populateUserList () {
      if (!this.user.companyGroup.id) {
        this.groupUsers = []
        return
      }

      try {
        const userCompanyGroupId = this.user.companyGroup.id
        const { page } = this
        const { limit } = this

        const params = {
          userCompanyGroupId,
          page,
          limit
        }
        this.groupUsers = await this.loadGroupMembers(params)
        for (const user of this.groupUsers) { user.isSelected = false }
        for (const user of this.groupUsers) { user.isCurrentUser = false }

        const currentUserIndex = this.groupUsers.findIndex(x => x.uId === this.user.id)
        let currentUser = this.groupUsers[currentUserIndex]
        if (this.task) {
          this.task.assignedUsers.forEach(user => {
            const selectedIndex = this.groupUsers.findIndex(x => x.uId === user.id)
            if (selectedIndex > -1) {
              this.groupUsers[selectedIndex].isSelected = true
              if (this.groupUsers[selectedIndex].uId === currentUser.uId) {
                this.groupUsers[selectedIndex].isCurrentUser = true
                this.groupUsers.unshift(this.groupUsers[selectedIndex])
              }
              this.selectedAssignees.push({ userId: user.id })
            }
          })
        } else {
          currentUser = this.groupUsers.splice(currentUserIndex, 1)[0]
          currentUser.isCurrentUser = true

          currentUser.isSelected = true
          this.selectedAssignees.push({ userId: currentUser.uId })
          this.groupUsers.unshift(currentUser)
        }
        this.groupUsers = Array.from(new Set(this.groupUsers))
      } catch (error) {
        this.$messaging.addErrorMessage('An error occurred . Please try again and contact support if the issue continues.')
      }
    },

    handleAssign (selected) {
      this.selectedAssignees = selected
    },

    async saving (fn) {
      this.isDataSubmitted = true
      await fn()
      this.isDataSubmitted = false
    },

    async createTask () {
      const userTask = {
        dueDate: this.formatDate(this.deadline),
        details: this.processTaskDetails(this.taskDetails),
        assignments: this.selectedAssignees
      }
      this.saving(async () => {
        await this.createUserTask(userTask).then((createdUserTask) => {
          this.$emit('created', createdUserTask.id)
        })
      })
    },

    async updateTask () {
      const userTask = pick(this.task, ['id'])
      userTask.details = this.processTaskDetails(this.taskDetails)
      userTask.dueDate = this.formatDate(this.deadline)
      userTask.assignments = this.selectedAssignees

      this.saving(async () => {
        await this.updateUserTask(userTask).then((updatedUserTask) => {
          this.$emit('updated', updatedUserTask.id)
        })
      })
    },

    ...mapActions([
      'createUserTask',
      'updateUserTask',
      'loadGroupMembers'
    ])

  }

}
</script>

<style scoped>
.dialog :deep(.dialog-content) {
    overflow-y: unset;
    top: 10%;
}
.dialog :deep(.dialog-container-width-grow) {
    min-width: 500px;
}
.dialog :deep(.dialog-container-grow) {
    min-width: 500px;
}
.dialog :deep(.dialog-container) {
    margin-top:-10em;
}
.spinner-submit{
    position:fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.task-details{
    font-size: 11px;
    line-height: 1.5em;
    margin-bottom: 20px;
  }
.task-details::after {
    content: "*";
    color: red;
    margin-right: 4px;
}
.assign{
    font-size: 11px;
    line-height: 1.5em;
    margin-bottom: 10px;
  }

</style>
