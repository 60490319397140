export default [
  {
    path: '/client/:id',
    name: 'ClientView',
    component: () => import('@/views/client/view/Index.vue'),
    children: [
      {
        path: 'campaigns',
        alias: '',
        component: () => import('@/views/client/view/campaigns/Index.vue'),
        children: [
          {
            path: 'all',
            alias: 'all-campaigns',
            name: 'ClientAllCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'my',
            alias: ['', 'my-campaigns'],
            name: 'ClientMyCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'owned',
            alias: 'owned-campaigns',
            name: 'ClientOwnedCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'created',
            alias: 'created-campaigns',
            name: 'ClientCreatedCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'collaborated',
            alias: 'collaborated-campaigns',
            name: 'ClientCollaboratedCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'salesforce-opportunities/:externalSystemId',
            name: 'ClientSalesforceCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'adpoint-opportunities/:externalSystemId',
            name: 'ClientAdpointCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'sell-opportunities/:externalSystemId',
            name: 'ClientSellCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'naviga-opportunities/:externalSystemId',
            name: 'ClientNavigaCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'titan-orders/:externalSystemId',
            name: 'ClientTitanCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          },
          {
            path: 'microsoft-opportunities/:externalSystemId',
            name: 'ClientMicrosoftCampaigns',
            component: () => import('@/views/client/view/campaigns/List.vue'),
            meta: {
              name: 'ClientCampaigns'
            }
          }
        ]
      },
      {
        path: 'info',
        name: 'ClientInfo',
        component: () => import('@/views/client/view/Info.vue')
      },
      {
        path: 'insights',
        name: 'ClientInsights',
        component: () => import('@/views/client/view/Insights.vue'),
        meta: {
          contentExtraClass: 'no-content-padding',
          mainContentClass: 'main-content-full-width'
        }
      },
      {
        path: 'billing-details',
        name: 'ClientBillingDetails',
        component: () => import('@/views/client/view/BillingDetails.vue')
      }
    ],
    meta: {
    // navItem: 'Clients',
      navItem: 'My Workspace',
      headerText: '{{CLIENT}}',
      headerLogo: 'client',
      headerBackRoute: 'WorkspaceClientList',
      subNav: 'client'
    }
  }
]
