<template>
  <div>
    <div
      class="select-container"
    >
      <a-label
        v-if="label !== ''"

        :class="warningMessage ? ' label-with-warning' : required ? ' required-label': ''"
      >
        {{ label }}
        <icon-link
          v-if="warningMessage"
          class="warning-icon"
          icon="Warning"
          w="12"
          h="12"
          :tooltip="warningMessage"
        />
      </a-label>
      <div
        v-if="filteredSelectOptionsLinked.length>0 && clientEdit"
        class="multi-select-pill"
      >
        <div
          class="multi-select-item-container"
        >
          <div>
            <div
              v-for="(option,index) in filteredSelectOptionsLinked"
              :key="index"
              class="d-flex flex-row option-item justify-content-between align-items-center"
              @click="unPairAgency(option)"
            >
              <div v-if="option.name!=prompt">
                <span
                  class="overflow-text"
                  v-html="highlightQuery(option.name)"
                />
              </div>
              <span
                v-else
                class="overflow-text"
              >{{ option.name }}</span>
              <div class="option-linked d-flex align-items-center justify-content-between ">
                <div class="role-pill">
                  Paired
                </div>
                <icon-link
                  v-if="clientEdit"
                  icon="Remove"
                  w="20"
                  h="20"
                  :disabled="!canUnpairOption(option)"
                  @click="$event => unPairAgency(option)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="false-select default no-border select carat overflow-text"
        :class="disabled?'disabled disabled-select':''"
        @click="toggleOptions"
      >
        <div
          v-if="!clientEdit"
          class="selected-option-text"
        >
          {{ selectedOptionText }}
        </div>
        <div
          v-else
        >
          <span
            class="add-new-item d-flex flex-row"
            data-test="add-item"
          >
            <icon-link
              icon="AddCircle"
              w="22"
              h="22"
              class="add-new-button"
              @click="toggleOptions"
            />
            <p
              class="add-new-text"
            >
              {{ selectedOptionText }}
            </p>
          </span>
        </div>
      </div>
      <div
        v-if="showOptions"
        :class="clientEdit ? ' dropdown-container-client-edit' : 'dropdown-container'"
      >
        <a-input
          ref="searchStringInput"
          v-model="searchString"
          class="search-box"
          placeholder="Search"
        />
        <div
          :class="clientEdit ? ' scroll-wrapper-client-edit' : !showAddAgency?'scroll-wrapper-agency-edit':'scroll-wrapper'"
        >
          <div
            v-if="!clientEdit"
            :key="linkedAgencyKey"
            class="section"
          >
            <div
              v-if="filteredSelectOptionsLinked.length>0"
              class="title"
            >
              Agencies Paired with Client
            </div>
            <div
              v-for="(option,index) in filteredSelectOptionsLinked"
              :key="index"
              class="d-flex flex-row option-item justify-content-between align-items-center"
              @click="selectRow(option)"
            >
              <div v-if="option.name!=prompt">
                <span
                  class="overflow-text"
                  v-html="highlightQuery(option.name)"
                />
              </div>
              <span
                v-else
                class="overflow-text"
              >{{ option.name }}</span>

              <div class="option-linked d-flex align-items-center justify-content-between ">
                <checkmark-outlined-icon
                  v-if="selectedValue?option.id===selectedValue.id:false"
                  w="25"
                  h="25"
                  data-test="checkmark-circle-icon"
                />
                <div class="role-pill">
                  Paired
                </div>
                <icon-link
                  v-if="clientEdit"
                  icon="Remove"
                  w="20"
                  h="20"
                  @click="$event => unPairAgency(option)"
                />
              </div>
            </div>
          </div>
          <div
            v-if="filteredSelectOptionsLinked.length>0 && !clientEdit && filteredSelectOptionsOther.length>0"
            class="wrapper"
          />
          <div
            :key="otherAgencyKey"
            class="section"
          >
            <div
              v-if="filteredSelectOptionsOther.length>0"

              class="title"
            >
              Other Agencies
            </div>

            <div
              v-for="(option,index) in filteredSelectOptionsOther"
              :key="index"
              :class="clientEdit ? ' option-item' : 'option-item d-flex justify-content-between'"
              @click="clientEdit?pairAgency(option):selectRow(option)"
            >
              <div
                v-if="option.name!=prompt"
                class="d-flex align-items-center justify-content-between"
              >
                <span
                  class="overflow-text"
                  v-html="highlightQuery(option.name)"
                />
                <icon-link
                  v-if="clientEdit"
                  icon="AddCircle"
                  w="20"
                  h="20"
                  class="add-icon"
                  @click="$event => pairAgency(option)"
                />
              </div>
              <checkmark-outlined-icon
                v-if="selectedValue?option.id===selectedValue.id:false"
                w="25"
                h="25"
                data-test="checkmark-circle-icon"
              />
            </div>
          </div>
        </div>

        <div
          v-if="hasEditPermission && showAddAgency"
          class="footer-slot"
        >
          <div class="wrapper-close" />
          <div
            v-if="showAgencyNameInput"
            class="d-flex flex-row  align-items-center justify-content-between"
          >
            <div class="fg agency-create-container">
              <a-input
                ref="newAgencyInput"
                v-model="newAgency"
                class="agency-box"
                :invalid="!checkingAgencyName && (error.name.val|| agencyExists)"
                :invalid-text="agencyNameInvalidText"
                @change="validateAgencyName"
              />
              <div class="agency-check">
                <generic-spinner
                  v-if="checkingAgencyName"
                  :class="` agency-name-checkmark `+(agencyExists ? 'client-name-spinner-during-warning' : 'client-name-spinner')"
                />

                <warning-icon
                  v-else-if="agencyExists"
                  w="25"
                  h="25"
                  title="Agency exists"
                  class="warning-icon-agency agency-name-checkmark"
                />

                <checkmark-circle-icon
                  v-else-if="agencyReadyToSave && newAgency!=''"
                  w="25"
                  h="25"
                  title="Agency name ok!"
                  class="checkmark-icon agency-name-checkmark"
                  data-test="checkmark-circle-icon"
                />
              </div>
            </div>
            <a-button
              :disabled="!agencyReadyToSave || newAgency===''"
              variant="primary"
              size="small"
              class="save-button"
              @click="saveAgency"
            >
              Save
            </a-button>
          </div>
          <div
            v-else
            class="d-flex flex-row align-items-center justify-content-between add-agency-btn"
          >
            <div
              class="d-flex flex-row align-items-center justify-content-between cursor-pointer"
              @click="addNewAgency()"
            >
              <icon-link
                icon="AddCircle"
                w="20"
                h="20"
                class="add-icon"
                @click="addNewAgency()"
              />
              <div class="text-blue-500 agency-add-text">
                Add New {{ label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, isEmpty } from 'lodash-es'
import { mapActions } from 'vuex'
import { CheckmarkCircleIcon, WarningIcon, CheckmarkOutlinedIcon } from '@/components/icons'

export default {
  name: 'ASelectAgency',
  components: {
    CheckmarkCircleIcon,
    WarningIcon,
    CheckmarkOutlinedIcon
  },
  props: {
    selectOptionsLinked: {
      type: Array,
      required: true
    },
    selectOptionsOther: {
      type: Array,
      required: true
    },
    selectedAgencies: {
      type: Array,
      default: () => []
    },
    selectedCompany: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    warningMessage: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    limt: {
      type: Number,
      default: 10
    },
    value: {
      type: Number,
      default: -1
    },
    currentPage: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prompt: {
      type: String,
      required: true
    },
    sentinal: {
      type: Number,
      default: 1
    },
    clientEdit: {
      type: Boolean,
      default: false
    },
    canUnpair: {
      type: Array,
      default: () => []
    },
    selectedAgency: {
      type: Object,
      default: null
    },
    showAddAgency: {
      type: Boolean,
      default: true
    }

  },

  data () {
    return {
      searchString: null,
      selectedValue: null,
      showOptions: false,
      selectedOptionText: null,
      newAgency: '',
      error: {
        name: {
          val: false,
          message: ''
        }
      },
      agencyExists: false,
      checkingAgencyName: false,
      checkingAgencyTimer: null,
      agencyReadyToSave: false,
      showAgencyNameInput: false,
      linkedAgencyKey: 'linked',
      otherAgencyKey: 'other',
      selectedAgenciesToLink: []
    }
  },

  computed: {

    filteredSelectOptionsLinked: {
      get () {
        return this.selectedAgencies.length === 0 ? this.selectOptionsLinked : this.selectedAgencies
      }
    },
    filteredSelectOptionsOther: {
      get () {
        // return this.selectOptionsOther.filter(p => !this.selectedAgencies.find(p2 => p2.id === p.id))
        return this.selectOptionsOther
      }
    },
    agencyNameInvalidText: {
      get () {
        const name = this.newAgency.trim()
        const isEmpty = name.length === 0
        const exceedsMaxLength = name.length > 100
        if (isEmpty) {
          return 'Name is required'
        }
        if (exceedsMaxLength) {
          return 'Max 100 characters'
        }

        if (this.agencyExists && !this.checkingAgencyName) {
          return 'Agency name already exists'
        }

        return ''
      }
    },
    permissions: {
      get () {
        return {
          editAll: this.$permission.hasPermission('AgencyEditAll'),
          editOwnCompany: this.$permission.hasPermission('AgencyEditForOwnCompany')
        }
      }
    },
    hasEditPermission: {
      get () {
        return this.permissions.editAll || this.permissions.editOwnCompany
      }
    }
  },

  watch: {
    searchString: debounce(async function (newSearch, prevSearch) {
      const { searchString } = this.filters()
      this.$emit('searchFilter', searchString.toLowerCase())
    }, 500),
    sentinal () {
      this.searchString = ''
    },
    selectedValue () {
      this.selectedOptionText = this.selectedValue.name
    },
    newAgency () {
      this.onAgencyNameChange()
    }
  },

  created () {
    this.selectedValue = this.selectedAgency
    if (this.selectedValue === null) this.selectedOptionText = this.prompt
  },

  mounted () {
    document.addEventListener('mousedown', this.close)
  },

  beforeDestroy () {
    this.showAgencyNameInput = false
    this.error.name.val = false
    this.agencyReadyToSave = false
    this.newAgency = ''
    document.removeEventListener('mousedown', this.close)
  },

  methods: {

    canUnpairOption (option) {
      // parseInt(option.campaignCount) > 0
      return !(this.canUnpair.some(unpair => option.id === unpair.agencyId))
    },

    highlightQuery (string) {
      // if there's no searchstring, just return the string without highlighting
      if (!this.searchString) return string

      // we need to escape special characters cause they break the regex
      const escapedSearchString = this.searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      // create a regex with the searchstring
      const regex = new RegExp(escapedSearchString, 'gi')

      // if there's a match, highlight it
      const update = string.replace(regex, (match) => `<span style="text-decoration-offset: 2px; text-decoration-line: underline; background-color: #FFFB72; text-decoration-color: #2e76bc; text-decoration-thickness: 2px; text-decoration-style: solid;">${match}</span>`)
      return update
    },
    async saveAgency () {
      try {
        const agency = {
          name: this.newAgency,
          companyId: this.selectedCompany,
          isActive: true,
          useDefaultCompanyCommission: true
        }
        const createdAgency = await this.saveNewAgency(agency)
        this.newAgency = ''
        this.$messaging.addSuccessMessage('Agency added successfully!')
        this.selectRow(createdAgency)
        this.showOptions = false
      } catch (err) {
        this.$messaging.addErrorMessage(err)
      }
    },
    async pairAgency (option) {
      const index = this.filteredSelectOptionsOther.findIndex(x => x.id === option.id)
      if (index > -1) this.filteredSelectOptionsOther.splice(index, 1)
      this.filteredSelectOptionsLinked.push(option)
      this.filteredSelectOptionsLinked.sort(function (a, b) {
        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
      this.$emit('selected', this.filteredSelectOptionsLinked)
    },
    async unPairAgency (option) {
      // if (parseInt(option.campaignCount) > 0) return
      if (!this.canUnpairOption(option)) return

      const index = this.filteredSelectOptionsLinked.findIndex(x => x.id === option.id)
      if (index > -1) this.filteredSelectOptionsLinked.splice(index, 1)
      this.filteredSelectOptionsOther.push(option)
      this.filteredSelectOptionsOther.sort(function (a, b) {
        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
      this.$emit('selected', this.filteredSelectOptionsLinked)
    },

    requestFetch () {
      this.$emit('fetchPage', this.currentPage + 1)
    },

    toggleOptions () {
      if (!this.disabled) {
        this.showOptions = !this.showOptions
        if (!this.selectOptions || this.selectOptions < 0) {
          this.requestFetch()
        }

        if (this.showOptions) {
          this.$nextTick(() => {
            this.focusSearch()
          })
        }
      }
    },

    focusSearch () {
      this.$refs.searchStringInput.inputElement.focus()
    },

    selectRow (option) {
      if (this.clientEdit) return
      if (this.selectedValue && this.selectedValue.id === option.id) {
        this.selectedValue = {
          id: null,
          name: this.prompt
        }
        this.$emit('selected', null)
      } else {
        this.$emit('selected', option)
        this.selectedValue = option
      }
      this.showOptions = false
      this.showAgencyNameInput = false
      this.error.name.val = false
    },

    close (e) {
      if (!this.$el.contains(e.target)) {
        this.searchString = ''
        this.showOptions = false
        this.showAgencyNameInput = false
        this.error.name.val = false
        this.newAgency = ''
        this.agencyReadyToSave = false
      }
    },

    filters () {
      const searchString = this.searchString
      const optionId = this.selectedOptionId

      return { searchString, optionId }
    },
    validateAgencyName () {
      const name = this.newAgency.trim()
      const isEmpty = name.length === 0
      const exceedsMaxLength = name.length > 100
      this.error.name.val = isEmpty || exceedsMaxLength || this.agencyExists
    },
    async onAgencyNameChange () {
      if (this.newAgency === '') return
      this.checkingAgencyName = true
      this.agencyReadyToSave = false

      this.error.name.val = false
      if (this.checkingAgencyTimer) clearTimeout(this.checkingAgencyTimer)

      if (this.newAgency !== '' && this.newAgency === this.initialName) {
        this.agencyExists = false
        this.checkingAgencyName = false
      } else if (!this.newAgency) {
        this.agencyExists = true
        this.checkingAgencyName = false
      } else {
        this.checkingAgencyTimer = setTimeout(async () => {
          await this.checkAgencyName(this.newAgency)
          this.checkingAgencyName = false
        }, 1000)
      }
    },

    async checkAgencyName () {
      try {
        const agency = await this.findAgencyByNameAndCompany({
          name: this.newAgency,
          companyId: this.selectedCompany
        })
        if (!isEmpty(agency)) {
          this.agencyExists = true
        } else {
          this.agencyExists = false
          this.agencyReadyToSave = true
        }
      } catch (error) {
        this.$messaging.addErrorMessage(error)
      }
    },
    addNewAgency () {
      this.showAgencyNameInput = true
      setTimeout(() => {
        this.$refs.newAgencyInput.inputElement.focus()
      }, 200)
    },
    ...mapActions([
      'findAgencyByNameAndCompany',
      'saveNewAgency'
    ])
  }
}

</script>

<style scoped>
.select-container {
  position: relative;
}

.select {
  font-family: var(--font-stack);
  width: 100%;
  border-color: var(--input-border-color);
  border-style: solid;
  appearance: none;
  cursor: pointer;
  color: var(--text-color);
}

.disabled-select {
    opacity: 0.4;
}

.select:not(.multiple) {
  padding-right: 30px;
  background-color: white;
}

.carat:not(.multiple) {
  background-image: url("/images/carat_down.png");
  background-position: center right;
  background-repeat: no-repeat;
  height:30px;
}

.select:focus {
  border-color: var(--input-focus-border-color);
}

.select-container > .false-select {
  border-width: 1px;
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 7px 30px 7px 6px;
  height: 36px;
}

.large .select {
  border-width: 0px 0px 1px 0px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.card-title .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.no-border .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-right: 22px;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 0;
}

.invalid .select,
select[aria-invalid='true'] {
  border-color: var(--text-error-color);
}

.select-container .invalid-input {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 0px;
}

.help-text {
  width: 100%;
  font-size: 12px;
  font-style: italic;
  color: var(--text-help-color);
  margin-top: 6px;
}

select.warning {
  border: solid var(--text-error-color) 1px;
}

.label-with-warning {
  display: flex !important;
}

.warning-icon {
  margin-left: 5px;
  height: 11px;
}

.warning-icon :deep(.icon),
.warning-icon :deep(.icon:hover) {
  fill: var(--text-warning-color) !important;
}

.required-label:after {
  content: '*';
  color: var(--text-error-color);
  font-weight: bold;
  font-size: 11px;
  margin-right: 3px;
}

.inactive-option {
  color: var(--text-help-color);
}

.select.disabled,
.select[disabled] {
  cursor: not-allowed;
}

.dropdown-container{
  position: absolute;
  z-index: 5;
  background: white;
  border-width: 1px;
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: rgb(223, 223, 223);
  font-size: 13px;
  padding: 7px 5px 0px 6px;
  width: 100%;
  height: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dropdown-container-client-edit{
    position: absolute;
    z-index: 100;
    background: white;
    border-width: 1px;
    border-radius: var(--border-radius);
    border-style: solid;
    border-color: rgb(223, 223, 223);
    font-size: 13px;
    padding: 7px 5px 0px 6px;
    width:400px;
    height:300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.option-item{
  padding: 5px;
  font-size: 12px;
  margin: 10px 10px 5px 10px;
  cursor: pointer;
}

.option-item.disabled{
  pointer-events:none;
  font-style:italic;
}

.dropdown-container :deep(.search-box.input ){
  margin-bottom: 5px;
}

.agency-create-container {
  position: relative;
}

.agency-box {
  margin: 0 !important;
}

.scroll-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 210px;
  width: 100%;
}

.scroll-wrapper-client-edit {
  overflow-y: auto;
  overflow-x: hidden;
  height: 210px;
  width: 100%;
}
.scroll-wrapper-agency-edit {
  overflow-y: auto;
  overflow-x: hidden;
  height: 260px;
  width: 100%;
}

.overflow-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.title {
  margin: 10px 10px 5px 10px;
  font-size: 10px;
  color: #666666;
}
.wrapper{
  border-bottom: 1px solid #ccc;
  margin: 10px 0px 0px 10px;
  width: 100%;
}
.role-pill {
  padding: 4px 8px;
  border-radius: 0.375rem;
  background-color: var(--edit-link-background-color);
  color: #666666;
  font-size: 10px;
  width:max-content;
}
.option-linked{
  padding-left: 10px;
}
.agency-add-text{
  margin-left: 10px;
  padding: 5px,5px;
  font-size: 12px;
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.p-1 {
  padding: 0.25rem;
}
.rounded-full {
  border-radius: 9999px;
}
.text-blue-500 {
  color: var(--link-color);
}
.cursor-pointer {
  cursor: pointer;
}
.footer-slot {
  padding: 7px 0;
}
.wrapper-close{
  border-bottom: 1px solid #ccc;
  margin-left: -7px;
  margin-right: -11px;
  margin-bottom:5px;
}
.spinner-agency{
  margin-top: 20px;
}

.add-agency-btn{
  margin-top:14px;
  margin-bottom: 4px;
  margin-left:10px;
}
.agency-check{
  position: absolute;
  top: 6px;
  right: 6px;
}
.agency-name-checkmark {
  margin-left: 5px;
  margin-bottom: 0px;
}
.agency-selected-checkmark{
  margin-right:5px
}

.checkmark-icon {
  fill: var(--text-success-color);
}

.warning-icon-agency {
  fill: var(--text-warning-color);
  margin-bottom: 15px;
}
.add-new-item {
  align-items: center;
  margin: 0px 5px;
  cursor: pointer;
}
.add-new-button {
  margin-right: 3px
}

.add-new-button :deep(div) {
  height: 22px;
}

.add-new-button :deep(div.icon) {
  fill: var(--text-help-color);
  cursor: pointer;
}

.add-new-button :deep(div.icon:hover) {
  fill: var(--text-help-color);
    opacity: .9;
}
.add-new-text {
  color: var(--text-help-color);
  cursor: pointer;
  line-height: 1;
  font-style: italic;
}

.add-new-text:hover {
  opacity: .9
}

.multi-select-item-container {
  background-color: #FFF;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);
  padding: 2px 6px;
  cursor: pointer;
  min-height: 36.3px;
}

.multi-select-pill {
  margin-bottom: 0px;
}

.selected-option-text{
  margin-top: 4px;
}
.save-button{
  height: 33px;
  margin-left: 7px;
}

  </style>
