import Vue from 'vue'

function list (params = {}) {
  const path = '/admin/user-notification-types'
  return Vue.api.get(path, { params })
}

function update (id, data = {}) {
  const path = `/admin/user-notification-types/${id}`
  return Vue.api.patch(path, { data })
}

export default {
  list,
  update
}
