<template>
  <div
    class="select-container"
    :class="disabled?'disabled':''"
  >
    <a-label
      v-if="label !== ''"
      :class="warningMessage ? ' label-with-warning' : required ? ' required-label': ''"
    >
      {{ label }}
      <icon-link
        v-if="warningMessage"
        class="warning-icon"
        icon="Warning"
        w="12"
        h="12"
        :tooltip="warningMessage"
      />
    </a-label>
    <div
      class="false-select default no-border select carat overflow-text"
      :class="disabled?'disabled':''"
      @click="toggleOptions"
    >
      {{ selectedOptionText }}
    </div>
    <div
      v-if="showOptions"
      class="dropdown-container"
    >
      <a-input
        ref="searchStringInput"
        v-model="searchString"
        class="search-box"
      />
      <div
        class="scroll-wrapper"
        @scroll="onScroll"
      >
        <div
          v-for="(option,index) in filteredSelectOptions"
          :key="index"
          class="option-item"
          :class="disabled?'disabled':''"
          @click="selectRow(option)"
        >
          <div v-if="option.text!=prompt">
            <span
              class="overflow-text"
              v-html="highlightQuery(option.text)"
            />
          </div>
          <span
            v-else
            class="overflow-text"
          >{{ option.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'

export default {
  name: 'ASelectFiltered',
  components: {
  },
  props: {
    selectOptions: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    warningMessage: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    limt: {
      type: Number,
      default: 10
    },
    value: {
      type: Number,
      default: -1
    },
    currentPage: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prompt: {
      type: String,
      required: true
    },
    sentinal: {
      type: Number,
      default: 1
    }

  },

  data () {
    return {
      searchString: null,
      selectedValue: null,
      showOptions: false
    }
  },

  computed: {
    selectedOptionText: {
      get () {
        return this.selectOptions?.filter(e => e.value === this.value)[0]?.text || this.prompt
      }
    },

    filteredSelectOptions: {
      get () {
        return this.selectOptions?.filter(e => this.selectOptions.length <= 1 || !e.disabled)
      }
    }
  },

  watch: {
    searchString: debounce(async function (newSearch, prevSearch) {
      const { searchString } = this.filters()
      this.$emit('searchFilter', searchString.toLowerCase())
    }, 500),
    sentinal () {
      this.searchString = ''
    }
  },

  created () {
  },

  mounted () {
    document.addEventListener('mousedown', this.close)
  },

  beforeDestroy () {
    document.removeEventListener('mousedown', this.close)
  },

  methods: {

    highlightQuery (string) {
      // if there's no searchstring, just return the string without highlighting
      if (!this.searchString) return string

      // we need to escape special characters cause they break the regex
      const escapedSearchString = this.searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      // create a regex with the searchstring
      const regex = new RegExp(escapedSearchString, 'gi')

      // if there's a match, highlight it
      const update = string.replace(regex, (match) => `<span style="text-decoration-offset: 2px; text-decoration-line: underline; background-color: #FFFB72; text-decoration-color: #2e76bc; text-decoration-thickness: 2px; text-decoration-style: solid;">${match}</span>`)
      return update
    },

    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.requestFetch()
      }
    },

    requestFetch () {
      this.$emit('fetchPage', this.currentPage + 1)
    },

    toggleOptions () {
      this.showOptions = !this.showOptions
      if (!this.selectOptions || this.selectOptions < 0) {
        this.requestFetch()
      }

      if (this.showOptions) {
        this.$nextTick(() => {
          this.focusSearch()
        })
      }
    },

    focusSearch () {
      this.$refs.searchStringInput.inputElement.focus()
    },

    selectRow (option) {
      this.$emit('selected', option)
      this.selectedValue = option
      this.showOptions = false
    },

    close (e) {
      if (!this.$el.contains(e.target)) {
        // this.$emit('close')
        this.showOptions = false
      }
    },

    filters () {
      const searchString = this.searchString
      const optionId = this.selectedOptionId

      return { searchString, optionId }
    }
  }
}

</script>

<style scoped>
.select-container {
  margin-bottom: 20px;
  position: relative;
}
.select {
  font-family: var(--font-stack);
  width: 100%;
  border-color: var(--input-border-color);
  border-style: solid;
  appearance: none;
  cursor: pointer;
  color: var(--text-color);
}

.disabled-select {
    opacity: 0.4;
}

.select:not(.multiple) {
  padding-right: 30px;
  background-color: white;
}

.carat:not(.multiple) {
  background-image: url("/images/carat_down.png");
  background-position: center right;
  background-repeat: no-repeat;
  height: 36px;
}

.select:focus {
  border-color: var(--input-focus-border-color);
}

.select-container > .false-select {
  border-width: 1px;
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 7px 30px 7px 6px;
  line-height: normal;
}

.large .select {
  border-width: 0px 0px 1px 0px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

.card-title .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.no-border .select {
  border-color: transparent;
  background-color: var(--card-background-color);
  padding-left: 0;
  padding-top: 0;
  padding-right: 22px;
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 0;
}

.invalid .select,
select[aria-invalid='true'] {
  border-color: var(--text-error-color);
}

.select-container .invalid-input {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 0px;
}

.help-text {
  width: 100%;
  font-size: 12px;
  font-style: italic;
  color: var(--text-help-color);
  margin-top: 6px;
}

select.warning {
  border: solid var(--text-error-color) 1px;
}

.label-with-warning {
  display: flex !important;
}

.warning-icon {
  margin-left: 5px;
  height: 11px;
}

.warning-icon :deep(.icon),
.warning-icon :deep(.icon:hover) {
  fill: var(--text-warning-color) !important;
}

.required-label:after {
  content: '*';
  color: var(--text-error-color);
  font-weight: bold;
  font-size: 11px;
  margin-right: 3px;
}

.inactive-option {
  color: var(--text-help-color);
}

.select.disabled,
.select[disabled] {
  cursor: not-allowed;
}

.dropdown-container{
  position: absolute;
  z-index: 5;
  background: white;
  border-width: 1px;
  border-radius: var(--border-radius);
  border-style: solid;
  border-color: rgb(223, 223, 223);
  font-size: 13px;
  width: 100%;
  max-height:200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 7px;
}

.option-item{
  padding: 5px;
  cursor: pointer;
}

.option-item.disabled{
  pointer-events:none;
  font-style:italic;
}

.search-box {
  margin-bottom: 7px;
}

.scroll-wrapper{
  overflow-y: scroll;
  max-height: 165px;
  width: 100%;
}

.overflow-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.disabled{
  opacity: .5 !important;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
